import React from 'react';

import {
    Container,
    Jumbotron
} from 'reactstrap';

import logo from '../../assets/logo.png';


const Header = () => {
    return (
        <div className="header">
                <img src={logo} className="img img-fluid mx-auto d-block logo" alt="Seattle ReactJS logo" />
        </div>
    );
};

export default Header;