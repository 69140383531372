import React from 'react';

import {
  Link
} from 'react-router-dom';

import {
    Container,
} from 'reactstrap';

import config from '../../config';

const Navigation = () => {
    const navItem = ({name, path}) => {
      return (
        <li className="navigation">
          <Link to={path}>{name}</Link>
        </li>
      )
    };

    return (
        <nav class="navbar navbar-expand-sm justify-content-center">
          <ul className="navbar-nav">
            {config.pages.map(navItem)}
          </ul>    
        </nav>
    );
};

export default Navigation;