import React from 'react';

import Talk from './Talk';

export default (props) => {
    const {host, date, talks} = props;

    return (
        <div style={{marginBottom: '50px', borderStyle: "solid", borderWidth: '1px', padding: '20px'}}>
            <div className="row">
                <div className="col-md-12">
                    <h2>{date} at {host}</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    {talks.map((talk) => <Talk {...talk} />)}
                </div>
            </div>
        </div>
    );
}