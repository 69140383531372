import React from 'react';


export default (props) => {
    const {title, speakers, video_url, type} = props;

    let recording;

    switch (video_url) {
        case '':
            recording = <a className="btn btn-info">Coming Soon!</a>
            break;
        
        case '!':
            recording = <a className="btn btn-error">Not Recorded</a>
            break;

        default: 
            recording = <a href={video_url} className="btn btn-success" target="blank">View on YouTube!</a>

    }

    return (
        <div class="row" style={{paddingTop: '15px', paddingLeft: '20px', paddingBottom: '15px'}}>
            <div class="col-md-12">
                <h3 className="text-peach" style={{marginBottom: '0px'}}>{speakers.join(', ')}</h3>
                <p style={{marginBottom: '5px', color: ''}}>
                {title}
                </p>
                {recording}
            </div>
        </div>
    );
}