import React from 'react';

import "../stylesheets/speaking.css";

export default () => {
    return (
        <div>
            <h1>Speak At Our Next Event</h1>
            <p>
                We're always looking for speakers! We encourage speakers of all levels to submit their talks to us.
            </p>

            <div style={{marginBottom: '30px'}}>
                <h2 className="text-peach" style={{marginBottom: '0px'}}>Time Slots</h2>
                <ul style={{marginBottom: '0px'}}>
                    <li>Lightning Talk <span className="text-secondary">(3-5 Minutes)</span></li>
                    <li>Presentation <span className="text-secondary">(5-15 Minutes)</span></li>
                    <li>Technical Talk <span className="text-secondary">(15-30 Minutes)</span></li>
                </ul>
                <p><small>Talks longer than 30 minutes will be considered on a case-by-case basis.</small></p>
            </div>

            <div style={{marginBottom: '30px'}}>
                <h2 className="text-peach">Supporting First Time Speakers</h2>
                <p>
                    If this is your first talk, let us know; we'd love to help!
                </p>
                <p>
                   We want to be supportive of new speakers and we will do our best to prioritize at least one new 
                   speaker at each event! 
                </p>
            </div>

            <div style={{marginBottom: '30px'}}>
                <h2 className="text-peach">Does my talk have to be about React?</h2>
                <p>
                    Nope! We're a React focused community, but not exclusive! There are so many topics that are valuable to all developers. 
                </p>
                <ul>
                    <li>Projects: things you've built, seen, or want to build</li>
                    <li>Social and Professional Skills: talks don't have to be technical!</li>
                    <li>Tools: the things you use to get the job done</li>
                    <li>Testing: how do you ensure code quality?</li>
                    <li>Frameworks: what else is on the horizon?</li>
                </ul>
            </div>
        </div>
    );
}