import React from 'react';

export default () => {
    return (
        <div>
            <h1>Host Our Next Event</h1>
            <p>
                Our meetup is made possible by local sponsors. If you have space or funding for food, we'd love to have you as a sponsor!
                If you're interested in sponsoring please reach out to Patrick at <a href="mailto:setfloat@gmail.com">setfloat@gmail.com</a>.
            </p>

            <h2 className="text-peach">Venue and Seating</h2>
            <p>
                Our meetups are very well attended. We work with our hosts to determine capacity but we generally shoot for seating for <strong>75 - 300 people</strong>.
            </p>

            <h2 className="text-peach">Food and Refreshments</h2>
            <p>
                If you'd like to sponsor our event but don't have space to host folks, we'd love to have you cover food and beverages! Once we have a host, we'll be able
                to give you an attendee count. Our sponsors generally decide on the food options, but we encourage being inclusive of folks with dietary restrictions (vegan, GF, common food allergies).
            </p>
        </div>
    )
}